"use client";
import { HTMLAttributes } from "react";
export const iconDefsPath = "icon-defs.svg";
import React from "react";
export interface IconProps extends HTMLAttributes<SVGElement> {
  idKey?: any;
  name: string;
  gradient?: string;
  variant?: IconVariant;
  size?: IconSize;
  color?: IconColor;
}
type IconVariant = "standard" | "circle";
type IconSize = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
type IconColor = "dark" | "primary" | "success" | "error";
type MappedIconSize = {
  [key in IconSize]: string;
};
type IconVariance = {
  [key in IconVariant]: {
    base: string;
    color: MappedIconColor;
    size: MappedIconSize;
  };
};
type MappedIconColor = {
  [key in IconColor]: string;
};

const ICON_VARIANCE: IconVariance = {
  standard: {
    base: "inline-flex justify-center items-center",
    color: {
      dark: "fill-secondary-300 text-secondary-300",
      primary: "fill-primary text-primary",
      success: "fill-success text-success",
      error: "fill-error text-error",
    },
    size: {
      "2xs": "w-[6px] h-[6px]",
      xs: "w-3 h-3",
      sm: "w-4 h-4",
      md: "w-5 h-5",
      lg: "w-6 h-6",
      xl: "w-9 h-9",
      "2xl": "w-14 h-14",
    },
  },
  circle: {
    base: "inline-flex justify-center items-center rounded-full",
    color: {
      dark: "fill-secondary-300 text-secondary-300 bg-surface",
      primary: "fill-primary text-primary bg-dark",
      success: "fill-success-300 text-success-300 bg-success-700",
      error: "fill-error-300 text-error-300 bg-error-700",
    },
    size: {
      "2xs": "p-0",
      xs: "p-0.5",
      sm: "p-1",
      md: "p-2",
      lg: "p-3",
      xl: "p-4",
      "2xl": "p-9",
    },
  },
};

// const gradients = [
//   {
//     name: "flame",
//     gradient: () => {
//       return (
//         <linearGradient
//           id={`flame`}
//           x1="9.66869"
//           y1="1"
//           x2="9.66869"
//           y2="19"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#FF0016" />
//           <stop offset="1" stopColor="#FF9900" />
//         </linearGradient>
//       );
//     },
//   },
//   {
//     name: "cold",
//     gradient: () => {
//       return (
//         <linearGradient
//           id={`cold`}
//           x1="12.011"
//           y1="1.20117"
//           x2="12.011"
//           y2="22.8012"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#55E0FF" />
//           <stop offset="1" stopColor="#5EA8FF" />
//         </linearGradient>
//       );
//     },
//   },
// ];

function getIconClasses(props: IconProps): string {
  return `${ICON_VARIANCE[props.variant || "standard"].base} ${
    props.color && ICON_VARIANCE[props.variant || "standard"].color[props.color]
  } ${ICON_VARIANCE[props.variant || "standard"].size[props.size || "md"]} ${
    props.className || ""
  }`;
}

export default function Icon(props: IconProps) {
  if (props.variant === "circle") {
    return (
      <div className={getIconClasses(props)}>
        <Inner
          idKey={props.idKey}
          name={props.name}
          gradient={props.gradient}
          className={ICON_VARIANCE.standard.size[props.size || "md"]}
        ></Inner>
      </div>
    );
  } else {
    return (
      <Inner
        {...props}
        idKey={props.idKey}
        className={getIconClasses(props)}
      ></Inner>
    );
  }
}

export function Inner(props: IconProps) {
  return (
    <>
      <svg
        className={props.className}
        aria-hidden="true"
        focusable="false"
        fill={props.gradient ? `url(#${props.gradient})` : "currentColor"}
      >
         {/* {props.gradient && (
          <defs>
            {gradients
              .find((g) => g.name === props.gradient)
              ?.gradient()}
          </defs>
        )} */}
       
        <use
          // xlinkHref={`/${iconDefsPath}#icon-${props.name}`}
          href={`#icon-${props.name}`}
          width="100%"
          height="100%"
          className="fill-inherit"
        />
      </svg>
    </>
  );
}
